@import "../../scss/variables";

*[class*="SectionWrap_container"] {
    .container {
        padding: 0;
        margin: 0 auto;
        max-width: 1200px;
    }
}

.container {
    display: flex;
    flex-wrap: wrap;
    margin: 48px auto;
    max-width: 1248px;
    padding: 0 24px;
}

.heading {
    flex: 0 0 35%;
    max-width: 35%; 
}

.title {
    color: $red;
    font-size: 35px;
    font-family: $serif;
    line-height: 48px;
    margin-right: 56px;
    letter-spacing: normal;
    text-align: left;
    text-transform: none;

    &:after {
        content: none;
    }
}

.content {
    flex: 0 0 65%;
    max-width: 65%; 
    flex-direction: column;

    .description {
        color: $grey;
        letter-spacing: normal;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .link {
        font-family: $sans-serif-medium;
        font-weight: $sans-medium;
    }
}

@media (max-width: $smallContainer) {
    *[class*="SectionWrap_container"] {
        .container {
            max-width: 852px;
        }
    }

    .container {
        max-width: 900px;
    }

    .title {
        font-size: 29px;
        margin-right: 32px;
    }
}

@media screen and (max-width: $mobile-smaller) {
    .container {
        margin: 36px auto !important; // override all above versions of padding
    }

    .heading,
    .content {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0;
    }

    .title {
        font-size: 30px;
        line-height: 38px;
        margin-right: 0;
    }
}
